$primary: #f5aa0a;

:local .notifyBox {
  composes: notifyBox from "./box.scss";
  border-left-color: $primary;
}

:local .notifyBoxIcon {
  composes: notifyBoxIcon from "./box.scss";
  color: $primary;
}

:local .notifyBoxContent {
  composes: notifyBoxContent from "./box.scss";
}

:local .notifyBoxTitle {
  composes: notifyBoxTitle from "./box.scss";
}

:local .notifyBoxMessage {
  composes: notifyBoxMessage from "./box.scss";
}
