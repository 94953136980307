.minhacuritiba-scheme .widget {
  color: #444;
}

.minhacuritiba-scheme .widget .button {
  color: white;
}

.minhacuritiba-scheme .bg-1 {
  background-color: #F9A51B;
}

.minhacuritiba-scheme .bg-2 {
  background-color: #1EABB4;
}

.minhacuritiba-scheme .bg-3 {
  background-color: #3C4F56;
}

.minhacuritiba-scheme .bg-1,
.minhacuritiba-scheme .bg-2,
.minhacuritiba-scheme .bg-3,
.minhacuritiba-scheme .bg-4,
.minhacuritiba-scheme .bg-5,
.minhacuritiba-scheme .bg-6,
.minhacuritiba-scheme .bg-7,
.minhacuritiba-scheme .bg-8 {
  .widget {
    color: white;
    h1, h2, h3, h4, h5, h6 { color: white }
  }
}
