@font-face {
  font-family: PF Din;
  src: url('/font-sets/pf-din/PFDinTextPro-Regular.otf');
}

@font-face {
  font-family: PF Din;
  src: url('/font-sets/pf-din/PFDinTextPro-Italic.otf');
  font-style: italic;
}

@font-face {
  font-family: PF Din;
  src: url('/font-sets/pf-din/PFDinTextPro-BoldItalic.otf');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: PF Din;
  src: url('/font-sets/pf-din/PFDinTextPro-Bold.otf');
  font-weight: bold;
}

.pfdin-header {
  h1, h2, h3, h4, h5, h6 {
    font-family: 'PF Din', sans-serif;
  }
}

.pfdin-body {
  font-family: 'PF Din', sans-serif;
}

.pfdin-form {
  label, input {
    font-family: 'PF Din', sans-serif;
  }
}
