.components--tab-border {
  border-top: none;
  border-right: none;
  border-bottom: 1px solid transparent;
  border-left: none;
  padding: 1rem 0;
  margin: 0 2rem 0 0;
  /* Inherited from basscss .btn class */
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  line-height: 1.125rem;
  height: auto;
  vertical-align: middle;
  -webkit-appearance: none;
  color: inherit;
  background-color: transparent;
}

.components--tab-border:hover {
  border-bottom-color: #999;
  text-decoration: none;
}

.components--tab-border.is-active {
  border-bottom-color: #000;
  font-weight: bold;
  color: #222;
}
