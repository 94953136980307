@import '../../../styles/color-variables';

.browsable-list {
  .browsable-list-item {
    padding: 1.1rem 2rem;
    border-bottom: 1px solid $whisper;
    display: block;
    text-decoration: none;
    background-color: $white;
    position: relative;
    overflow: hidden;
    transition: background-color .5s;

    &:hover {
      background-color: $gray95;
      .bg-animation-icon {
        right: -30px;
      }
      i.icon.right-icon {
        right: -30px;
      }
    }

    &:last-of-type {
      border: none;
    }

    .title {
      font-size: 1.5rem;
      font-weight: 100;
      display: inline;
      vertical-align: middle;
      padding-left: 1.2rem;
      color: $eclipse;
      position: relative;
      z-index: 1;
    }

    .subtitle {
      color: $gainsboro;
      font-weight: bold;
      display: inline-block;
      vertical-align: middle;
      padding-left: .8rem;
      font-size: 1.1rem;
      position: relative;
      z-index: 1;
    }

    i.icon {
      color: #000;
      font-size: 2.2rem;
      width: 40px;
      height: 40px;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      z-index: 1;

      &.right-icon {
        color: $gainsboro;
        font-size: 1.2rem;
        position: absolute;
        right: 20px;
        top: 25%;
        display: table;
        transition: right .5s;

        &:before {
          display: table-cell;
        }
      }

      &:before {
        vertical-align: middle;
      }
    }

    .bg-animation-icon {
      color: rgba(255,255,255,.7);
      font-size: 18rem;
      position: absolute;
      right: 100%;
      top: -98px;
      z-index: 0;
      transition: right .5s;
    }
  }
}
