:local {
  .code {
    padding: 3px 7px 5px;
    background-color: #efefef;
    border-radius: 2px;
  }

  .bordered {
    composes: code;
    background-color: #ffffff;
    border: 1px solid rgba(0,0,0,.12);
    font-style: normal;
  }
}
