.add-new-block {
  border: 2px #525256 dashed;
  display: block;
  padding: 10px;
  margin: 5px;
  text-align: center;
  font-size: 1.2rem;
  cursor: pointer;
  color: #525256;

  &:hover {
    color: #000;
    border-color: #000;
  }
}
