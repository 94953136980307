@import '~basscss-sass/scss/colors';

$blue             : #215d7a;
$darkblue         : #031092;
$aqua             : #2fbee6;
$darkGray         : #4a4a4a;
$darkenGray       : #222222;
$gray20           : #333333;
$eclipse          : #3e3e3e;
$gray94           : #eeeeee;
$gainsboro        : #dddddd;
$whisper          : #ededed;
$gray95           : #f2f2f2;
$lightGray        : #999999;
$lightestGray     : #cccccc;
$silver           : #f7f7f7;
$pagenta          : #ff0099;
$lavenderPink     : #fca7da;
$darkenMediumBlue : #031092;
$atomic           : #364C55;
$blacker          : #000000;
$white            : #ffffff;
$yellowGreen      : #7ED221;
