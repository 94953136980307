@import '../../../../../styles/color-variables';
@import '../_variables';

.list {
  .item {
    &:last-of-type {
      .more {
        .more-menu {
          top: -5px;
          right: 70px;
        }
      }
    }
    .more {
      .more-menu {
        position: absolute;
        background-color: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, .2);
        top: -5px;
        right: 70px;
        width: 0;
        line-height: 40px;
        white-space: nowrap;
        overflow: hidden;
        opacity: 0;
        padding: 10px 0;
        transition: opacity .2s ease-in, width .2s ease-in;

        &.show {
          opacity: 1;
          z-index: 3;
          width: 190px;
        }

        & > a, & > div {
          text-decoration: none;
          height: 40px;
          display: block;
          padding: 0 20px;

          &:hover {
            background-color: $gray95;
            cursor: pointer;
          }

          & > i.fa {
            font-size: 1.3rem;
            line-height: 30px;
            vertical-align: middle;
            padding-right: .8rem;
          }
        }
      }
    }
  }
}
