:local .tag {
  //
  // Bulma: Tag
  // @see https://jenil.github.io/bulmaswatch/materia/#tag
  //
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 290486px;
  color: #4a4a4a;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: .75rem;
  height: 2em;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.5;
  padding: .2rem 1.3em;
  white-space: nowrap;
  background-color: #e0e0e0;
  color: #555;
  margin-right: .5rem;
  margin-bottom: .5rem;
}
