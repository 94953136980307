@import '~basscss-sass/scss/background-colors';

.form {
  .form-group {
    border-bottom: 1px solid #eee;
    padding-top: $space-2;
    padding-bottom: $space-1;
    padding-left: $space-3;
    padding-right: $space-3;

    &:last-of-type {
      border-bottom: none;
      padding-bottom: .75rem;
    }

    label {
      margin-bottom: 0;
      font-size: .75rem;
      font-weight: 600;
      color: $gray;
      display: inline;

      & > .error {
        margin-left: $space-1;
        color: $red;
        font-weight: bold;
      }
    }

    .input,
    .textarea,
    .select {
      border: none;
      color: $black;
      margin: 0;
      padding: 0;
      display: block;
    }

    .textarea {
      margin-top: .3rem;
    }

    .select {
      margin-top: .3rem;
      background-color: $white;
    }
  }

  .control-buttons {
    input[type="submit"] {
      font-size: 1.1rem;
    }
  }
}
