.meurio-scheme .widget {
  color: #444;
}

.meurio-scheme .widget a {
  color: #119146
}

.meurio-scheme .widget .button {
  color: white;
}

.meurio-scheme .bg-1 {
  background-color: #039046;
}

.meurio-scheme .bg-2 {
  background-color: #2BBAE2;
}

.meurio-scheme .bg-3 {
  background-color: #FFCF01;
}

.meurio-scheme .bg-4 {
  background-color: #EF7F3A;
}

.meurio-scheme .bg-5 {
  background-color: #ED1C24;
}

.meurio-scheme .bg-6 {
  background-color: #DF499A;
}

.meurio-scheme .bg-7 {
  background-color: #8C78B7;
}

.meurio-scheme .bg-8 {
  background-color: #353A3D;
}

.meurio-scheme .bg-1,
.meurio-scheme .bg-2,
.meurio-scheme .bg-3,
.meurio-scheme .bg-4,
.meurio-scheme .bg-5,
.meurio-scheme .bg-6,
.meurio-scheme .bg-7,
.meurio-scheme .bg-8, {
  .widget {
    color: white;
    h1, h2, h3, h4, h5, h6 { color: white }
  }
}
