.block-create {
  .block-type {
    margin-bottom: 1.3rem;
    margin-top: 1.3rem;
  }

  .color-picker-container {
    display: inline-block;
    margin-right: 1rem;
    position: relative;
    top: -10px;
  }
}
