:local .kbd {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0,0,0,0.2), 0 0 0 2px #fff inset;
  color: #333;
  display: inline-block;
  font-family: Arial,Helvetica,sans-serif;
  font-size: 11px;
  line-height: 1.4;
  margin: 0 .1em;
  padding: .07em .6em;
  text-shadow: 0 1px 0 #fff;
}
