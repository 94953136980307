@import '../../styles/color-variables';

.errors-technical-issues {
  &:before {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $white;
    z-index: 0;
  }

  .container {
    z-index: 1;
    position: relative;
    overflow: auto;
    padding: 3rem 6rem 5rem;
    color: $darkGray;

    .logo-icon {
      background-image: url('./logo-icon.svg');
      background-size: contain;
      width: 40px;
      height: 40px;
      position: absolute;
      right: 6rem;
      bottom: 0;
    }

    a {
      color: $blacker;
      font-weight: bold;
    }

    h1 {
      font-size: 3.7rem;
      font-weight: 700;
      margin: 0;
      margin-bottom: 25px;

      &:after {
        content: '';
        width: 50px;
        height: 12px;
        background-color: $pagenta;
        display: block;
        margin-top: 10px;
      }
    }

    article {
      font-size: 1.5rem;
    }

    .contact {
      font-size: 1.6rem;
    }
  }
}
