@import '../../../../styles/color-variables';

.draft-widget-button {
  .btn {
    border: 1px solid $darkenGray;
    color: $lightGray;
    border-radius: 2px;
    text-align: center;
    font-size: .8rem;
    font-weight: 600;
    height: 100px;
    transition: .3s;
    position: relative;

    &:hover {
      background-color: rgba(255,255,255,.02);
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      i.fa {
        font-size: 2rem;
        position: relative;
        margin-bottom: 5px;
      }
    }
  }
}
