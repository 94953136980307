.minhablumenau-scheme .widget {
  color: #444;
}

.minhablumenau-scheme .widget .button {
  color: white;
}

.minhablumenau-scheme .bg-1 {
  background-color: #05A9CC;
}

.minhablumenau-scheme .bg-2 {
  background-color: #D6DF21;
}

.minhablumenau-scheme .bg-3 {
  background-color: #4B4C4D;
}

.minhablumenau-scheme .bg-1,
.minhablumenau-scheme .bg-2,
.minhablumenau-scheme .bg-3,
.minhablumenau-scheme .bg-4,
.minhablumenau-scheme .bg-5,
.minhablumenau-scheme .bg-6,
.minhablumenau-scheme .bg-7,
.minhablumenau-scheme .bg-8 {
  .widget {
    color: white;
    h1, h2, h3, h4, h5, h6 { color: white }
  }
}
