:local {
  .title {
    display: block;
    font-weight: normal;
    color: #222222;
  }

  .is-1 {
    composes: title;
    font-size: 2em;
    margin: .41em 0;
  }
  .is-2 {
    composes: title;
    font-size: 1.5em;
    margin: .56em 0;
  }
  .is-3 {
    composes: title;
    font-size: 1.17em;
    margin: .72em 0;
  }
  .is-4 {
    composes: title;
    font-size: 1em;
    margin: .84em 0;
  }
  .is-5 {
    composes: title;
    font-size: .83em;
    margin: 1.01em 0;
  }
}
