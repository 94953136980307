.step .header {
  padding: 2px 30px 2px;
  border-bottom: 1px solid #c7c7c7;
  max-height: 176px;
}
.step .content {
  padding: 15px 30px;
}
.step h3 {
  font-family: 'Source Sans Pro', 'Proxima Nova', sans-serif;
  font-size: 24px;
  line-height: 20px;
}
.step .ic-step {
  color: #fff;
  border-radius: 40px;
  display: inline-block;
  padding: 8px 14px;
  margin-right: 20px;
  font-size: 20px;
}
