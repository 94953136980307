.nossascidades-scheme .bg-1 {
  background-color: #D51067;
}

.nossascidades-scheme .bg-2 {
  background-color: #FA9ABA;
}

.nossascidades-scheme .bg-3 {
  background-color: #646569;
}

.nossascidades-scheme .bg-4 {
  background-color: #CAC8C8;
}

.nossascidades-scheme .bg-5 {
  background-color: #00C4B3;
}

.nossascidades-scheme .bg-6 {
  background-color: #00A7CE;
}

.nossascidades-scheme .bg-7 {
  background-color: #8C84D6;
}

.nossascidades-scheme .bg-8 {
  background-color: #FFC627;
}

.nossascidades-scheme .bg-9 {
  background-color: #039046;
}

.nossascidades-scheme .bg-10 {
  background-color: #2bbae2;
}

.nossascidades-scheme .bg-11 {
  background-color: #ffcf01;
}

.nossascidades-scheme .bg-12 {
  background-color: #ef7f3a;
}

.nossascidades-scheme .bg-13 {
  background-color: #ed1c24;
}

.nossascidades-scheme .bg-14 {
  background-color: #df499a;
}

.nossascidades-scheme .bg-15 {
  background-color: #8c78b7;
}

.nossascidades-scheme .bg-16 {
  background-color: #353a3d;
}

.nossascidades-scheme .bg-17 {
  background-color: #0fa7ac;
}

.nossascidades-scheme .bg-18 {
  background-color: #f0593e;
}

.nossascidades-scheme .bg-19 {
  background-color: #f4ed2f;
}

.nossascidades-scheme .bg-20 {
  background-color: #05a9cc;
}

.nossascidades-scheme .bg-21 {
  background-color: #d6df21;
}

.nossascidades-scheme .bg-22 {
  background-color: #4b4c4d;
}

.nossascidades-scheme .bg-23 {
  background-color: #12868b;
}

.nossascidades-scheme .bg-24 {
  background-color: #fcb814;
}

.nossascidades-scheme .bg-25 {
  background-color: #d01f53;
}

.nossascidades-scheme .bg-26 {
  background-color: #f9a51b;
}

.nossascidades-scheme .bg-27 {
  background-color: #1eabb4;
}

.nossascidades-scheme .bg-28 {
  background-color: #3c4f56;
}

.nossascidades-scheme .bg-29 {
  background-color: #00abcd;
}

.nossascidades-scheme .bg-30 {
  background-color: #bbd76a;
}

.nossascidades-scheme .bg-31 {
  background-color: #353b40;
}

.nossascidades-scheme .bg-32 {
  background-color: #fdc93b;
}

.nossascidades-scheme .bg-33 {
  background-color: #47928d;
}

.nossascidades-scheme .bg-34 {
  background-color: #fdc93b;
}

.nossascidades-scheme .bg-35 {
  background-color: #47928d;
}

.nossascidades-scheme .bg-36 {
  background-color: #45494d;
}

.nossascidades-scheme .bg-37 {
  background-color: #f68b1f;
}

.nossascidades-scheme .bg-38 {
  background-color: #f15622;
}

.nossascidades-scheme .bg-39 {
  background-color: #1a9cac;
}

.nossascidades-scheme .bg-40 {
  background-color: #ffffff;
}

.nossascidades-scheme .bg-1,
.nossascidades-scheme .bg-2,
.nossascidades-scheme .bg-3,
.nossascidades-scheme .bg-5,
.nossascidades-scheme .bg-6,
.nossascidades-scheme .bg-7,
.nossascidades-scheme .bg-9,
.nossascidades-scheme .bg-10,
.nossascidades-scheme .bg-11,
.nossascidades-scheme .bg-12,
.nossascidades-scheme .bg-13,
.nossascidades-scheme .bg-14,
.nossascidades-scheme .bg-15,
.nossascidades-scheme .bg-16,
.nossascidades-scheme .bg-17,
.nossascidades-scheme .bg-18,
.nossascidades-scheme .bg-19,
.nossascidades-scheme .bg-20,
.nossascidades-scheme .bg-21,
.nossascidades-scheme .bg-22,
.nossascidades-scheme .bg-23,
.nossascidades-scheme .bg-24,
.nossascidades-scheme .bg-25,
.nossascidades-scheme .bg-26,
.nossascidades-scheme .bg-27,
.nossascidades-scheme .bg-28,
.nossascidades-scheme .bg-29,
.nossascidades-scheme .bg-30,
.nossascidades-scheme .bg-31,
.nossascidades-scheme .bg-32,
.nossascidades-scheme .bg-33,
.nossascidades-scheme .bg-34,
.nossascidades-scheme .bg-35,
.nossascidades-scheme .bg-36,
.nossascidades-scheme .bg-37,
.nossascidades-scheme .bg-38,
.nossascidades-scheme .bg-39 {
  .widget {
    color: white;
    h1, h2, h3, h4, h5, h6 { color: white }
  }
}

.nossascidades-scheme .bg-4,
.nossascidades-scheme .bg-8,
.nossascidades-scheme .bg-40 {
  .widget {
    color: #444;
    h1, h2, h3, h4, h5, h6 { color: #444 }
  }
}
