:local {
  .backButton {
    position: absolute;
    left: 15px;
    top: -95px;
    border: none;
    background: transparent;
    font-size: 1.2rem;
    outline: none;
    cursor: pointer;
    transition: color .4s;
    color: #222222;
  }
  .backButton:hover {
    color: #CCCCCC;
  }
}
