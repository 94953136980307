:local {
  .previewContainer {
    background: #FFFFFF;
    border-right: 1px solid #EFEFEF;
  }
  .previewTitle {
    color: #222222 !important;
    line-height: 1.5 !important;
    font-size: 1.7em !important;
    margin: 0 !important;
    padding: 2rem 0 1rem;
    font-weight: bold;
    border-bottom: 1px solid #EFEFEF;
  }
  .previewTitleHelper {
    margin: 0;
    font-size: .85rem;
    font-weight: normal;
    color: #999999;
    line-height: .85rem;
  }
  .previewList {
    background: #FFFFFF;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #F8F8F8;
    height: 386px;
    overflow-y: auto;
  }
  .previewListItem {
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid #EFEFEF;
    background: #FFFFFF;
  }
  .previewListItemAvatar {
    width: 48px;
    height: 48px;
    margin: 0 .75rem 0 0;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .previewListItemGender {
    margin: 0 .75rem;
  }
  .previewListItemName {
    margin: 0 2rem 0 .75rem;
  }

  // Colors
  .pink { color: #FAA2E6 }
  .blue { color: #95DDFF }
  .gray { color: #999999 }
}
