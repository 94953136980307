.ux--flat-form {
  text-align: left;
  background-color: #fff;
  padding: 2rem 2rem 0;
  font-size: 0.875rem;
}

.ux--flat-form h1 {
  color: #222 !important;
  line-height: 1.5 !important;
  font-size: 2em !important;
  margin: 0 0 2rem !important;
  font-weight: bold;
  text-align: center;
}

.ux--flat-form h1.sm-margin {
  margin-bottom: 1rem !important;
}

.ux--flat-form h1.lg-margin {
  margin-bottom: 4rem !important;
}

.ux--flat-form .form-group {
  border: none !important;
}

.ux--flat-form .form-group .label {
  color: #aaa;
}

.ux--flat-form .separator {
  display: block;
  width: 500px;
  height: 1px;
  background-color: #eee;
  margin: 1.5rem -2rem;
}

.ux--flat-form .form-group {
  padding: 0 !important;
}

.ux--flat-form .form-group input,
.ux--flat-form .form-group textarea,
.ux--flat-form .form-group select {
  margin-top: 1rem !important;
}

.ux--flat-form .form-group label > span {
  margin: 0 !important;
}

.ux--flat-form .h6 {
  color: #aaa;
}

.ux--flat-form .h6 .fa {
  font-size: 22px;
}
