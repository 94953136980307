.widget .donation {
  a {
    color: #b0b0b0;

    &:hover { text-decoration: none; }
    &.btn { color: #fff; }
  }

  .error-icon {
    -webkit-mask-image: url('../../../mobilizations/images/thin-circular-cross.svg');
    mask-image: url('../../../mobilizations/images/thin-circular-cross.svg');
    -webkit-mask-size: 150px;
    mask-size: 150px;
    width: 150px;
    height: 150px;
  }

  .icon-payment-recurring,
  .icon-payment-unique {
    background: 50% 50% url('./images/donation-recurring.png') transparent no-repeat;
    background-size: contain;
    width: 2.2rem;
    height: 2.2rem;
    display: inline-block;
    filter: alpha(opacity=50);
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.5);
    opacity: .40;
    margin: 0 auto 1rem;
    display: block;
  }
  .icon-payment-unique {
    background-image: url('./images/donation-unique.png');
  }


  .payment-type {
    font-size: .9rem;
    text-decoration: none;

    span {
      margin-top: .7em;
      vertical-align: top;
    }
    span,img {
      display: inline-block;
    }

    &.active {
      .icon-payment-recurring,
      .icon-payment-unique {
        opacity: .8;
      }
    }
  }

  .value-option {
    border-radius: .3rem;
    box-sizing: border-box;
    border-width: .3rem;
    border-style: solid;
    border-color: #fff;
  }
}
