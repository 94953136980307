// Colors
$black:        hsl(0, 0%, 4%);
$black-bis:    hsl(0, 0%, 7%);
$black-ter:    hsl(0, 0%, 14%);

$grey-darker:  hsl(0, 0%, 21%);
$grey-dark:    hsl(0, 0%, 29%);
$grey:         hsl(0, 0%, 48%);
$grey-light:   hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);

$white-ter:    hsl(0, 0%, 96%);
$white-bis:    hsl(0, 0%, 98%);
$white:        hsl(0, 0%, 100%);

$orange:       hsl(14,  100%, 53%);
$yellow:       hsl(48,  100%, 67%);
$green:        hsl(141, 71%,  48%);
$turquoise:    hsl(171, 100%, 41%);
$blue:         hsl(217, 71%,  53%);
$purple:       hsl(271, 100%, 71%);
$red:          hsl(348, 100%, 61%);

// Primary colors
$primary: $turquoise;

$info: $blue;
$success: $green;
$warning: $yellow;
$danger: $red;

$light: $white-ter;
$dark: $grey-darker;

// Invert colors
$orange-invert: findColorInvert($orange);
$yellow-invert: findColorInvert($yellow);
$green-invert: findColorInvert($green);
$turquoise-invert: findColorInvert($turquoise);
$blue-invert: findColorInvert($blue);
$purple-invert: findColorInvert($purple);
$red-invert: findColorInvert($red);

$primary-invert: $turquoise-invert;
$info-invert: $blue-invert;
$success-invert: $green-invert;
$warning-invert: $yellow-invert;
$danger-invert: $red-invert;
$light-invert: $dark;
$dark-invert: $light;

// Sizes
$size-1: 3rem;
$size-2: 2.5rem;
$size-3: 2rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.75rem;

$border: $grey-lighter;
$text: $grey-dark;

$size-small: $size-7;
$size-normal: $size-6;
$size-medium: $size-5;
$size-large: $size-4;

$progress-bar-background-color: $border;
$progress-value-background-color: $text;

$colors: (
  "white": ($white, $black),
  "black": ($black, $white),
  "light": ($light, $light-invert),
  "dark": ($dark, $dark-invert),
  "primary": ($primary, $primary-invert),
  "info": ($info, $info-invert),
  "success": ($success, $success-invert),
  "warning": ($warning, $warning-invert),
  "danger": ($danger, $danger-invert)
);

@mixin block() {
  &:not(:last-child) {
    margin-bottom: 1.5rem
  }
}

.progress-container {
  color: #666;
  font-size: 1rem;
  line-height: 1rem;

  .progress {
    @include block();
    border: none;
    border-radius: 290486px;
    display: block;
    height: $size-normal;
    overflow: hidden;
    padding: 0;
    width: 100%;
    margin: 1rem 0 !important;
    background-color: $progress-bar-background-color;

    .progress-value {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 290486px;

      .percentage {
        font-size: .65rem;
        font-weight: bold;
      }

      // Colors
      @each $name, $pair in $colors {
        $color: nth($pair, 1);
        &.is-#{$name} { background-color: $color }
      }
    }
    // Sizes
    &.is-small { height: $size-small }
    &.is-medium { height: $size-medium }
    &.is-large { height: $size-large }
  }

  .progress-top, .progress-bottom {
    display: flex;
    justify-content: space-between;
    font-size: .8em;
  }

  .progress-top > div, .progress-bottom > div {
    flex-basis: 33.33%;
    flex-shrink: 0;
  }

  .progress-top-left:only-child,
  .progress-top-center:only-child,
  .progress-top-right:only-child {
    flex-basis: 100%;
  }

  .progress-top-left,   .progress-bottom-left   { text-align: left }
  .progress-top-center, .progress-bottom-center { text-align: center }
  .progress-top-right,  .progress-bottom-right  { text-align: right }


  @media screen and (max-width: 831px) {
    .progress { height: 25px }
    .percentage { font-size: 1rem !important }
    .progress-top > div,
    .progress-bottom > div {
      text-align: left;
      margin: .5rem;
    }
    .progress-top { font-size: 1.25rem }
    .progress-bottom {
      font-size: 1.05rem;
      flex-direction: column;
    }
    .progress-bottom-center { order: 1 }
    .progress-bottom-left { order: 2 }
    .progress-bottom-right { order: 3 }
  }
}