:local {
  .textarea {
    font-size: 1rem !important;
    line-height: 1.4rem;
    resize: vertical;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
  }

  .buttonDanger {
    background-color: rgba(255,0,0,.8);
    border-color: transparent;
    color: #ffffff;
    font-size: .8rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0px;
    border-radius: 3px;
    padding: 9px 19px;
    outline: none;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      background-color: rgba(240,0,0,.8);
    }

    &:active {
      box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.2);
    }

    &[disabled] {
      background-color: #cccccc;
    }
  }
}
