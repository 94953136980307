.mobilization-settings-sharing--form-share .facebook-share-image .upload-image-file {
  text-align: center;
}

.mobilization-settings-sharing--form-share .facebook-share-image .upload-image-file button {
  width: 220px;
  height: 220px;
}

.mobilization-settings-sharing--form-share .facebook-share-image .upload-image-file button .fa {
  font-size: 6rem;
}

.mobilization-settings-sharing--form-share .h6 .fa {
  font-size: 32px;
}
