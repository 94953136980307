.reboo-editor {
  .editor {
    position: relative;
    z-index: 2;
  }

  .toolbar-container {
    display: block;
    width: 100%;
  }

  .outside {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  // Align editor content
  .alignment--left {
    text-align: left;
  }
  figure.alignment--left {
    float: left;
    margin: .5rem;
  }
  .alignment--center {
    text-align: center;
  }
  figure.alignment--center {
    margin: .5rem auto;
  }
  .alignment--right {
    text-align: right;
  }
  figure.alignment--right {
    float: right;
    margin: .5rem;
  }
}
