@import '../../../styles/color-variables';

.tab {
  border-bottom-width: 3px;
  border-color: transparent;
  font-weight: normal;
  color: $lightGray;

  &:focus {
    box-shadow: none;
    border-color: $pagenta;
  }

  &.is-active,
  &.is-active:hover {
    border-color: $blacker;
    font-weight: bold;
    color: $darkenGray;
  }

  &:hover {
    border-color: $lightGray;
  }

  .circle {
    width: 28px;
    height: 28px;
    line-height: 28px;
    color: $white;
    font-weight: bold;
    font-style: normal;
    font-size: .9rem;
  }
}
