.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes shake {
  0%, 100% {-webkit-transform: translateX(0);}
  20%, 60% {-webkit-transform: translateX(-5px);}
  40%, 80% {-webkit-transform: translateX(5px);}
}
@keyframes shake {
  0%, 100% {transform: translateX(0);}
  20%, 60% {transform: translateX(-5px);}
  40%, 80% {transform: translateX(5px);}
}

.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes slice-up {
  from {-webkit-transform: translateY(0);}
  to {-webkit-transform: translateY(5px);}
}
@keyframes slice-up {
  from {-webkit-transform: translateY(0);}
  to {-webkit-transform: translateY(5px);}
}

.slice-up {
  -webkit-animation-name: slice-up;
  animation-name: slice-up;
}