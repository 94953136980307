a.wysihtml5-command-active,
a.wysihtml5-command-dialog-opened {
  background-color: $darken-3;
}

.wysiwyg-font-size-h6 { font-size: .5rem; }
.wysiwyg-font-size-h5 { font-size: 1rem; }
.wysiwyg-font-size-h4 { font-size: 1.5rem; }
.wysiwyg-font-size-h3 { font-size: 2rem; }
.wysiwyg-font-size-h2 { font-size: 3rem; }
.wysiwyg-font-size-h1 { font-size: 5rem; }
