:local {
  .summary {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .summaryHighlight {
    font-size: 3rem;
    font-weight: bold;
    line-height: 4rem;
    margin-right: 1rem;
  }
}
