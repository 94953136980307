$fa-font-path: "~font-awesome/fonts";
@import '~font-awesome/scss/font-awesome.scss';
@import './font-sets/proxima-nova/font-face.scss';
@import './font-sets/pf-din/font-face.scss';

@import './basscss_and_override';
@import './animations.scss';
@import './wysihtml5.scss';

@import './color_schemes/meurio.scss';
@import './color_schemes/minhasampa.scss';
@import './color_schemes/minhaportoalegre.scss';
@import './color_schemes/meurecife.scss';
@import './color_schemes/minhablumenau.scss';
@import './color_schemes/minhacampinas.scss';
@import './color_schemes/minhacuritiba.scss';
@import './color_schemes/minhagaropaba.scss';
@import './color_schemes/minhaouropreto.scss';
@import './color_schemes/nossascidades.scss';
@import './color_schemes/nuestrasciudades.scss';
@import './color_schemes/ourcities.scss';

@import './forms.scss';

//
// Documentation:
//
// z-index:
//    Sidenav                   6
//    NavBar                    3
//    Loading                   9
//    MobilizationList
//         - List Item          1
//         - Active List Item   2
//         - More Menu          3
//         - More Menu Cancel   1
//    Widgets - Content Plugin
//         - WYSIHTML editor
//             - Outside        1
//             - Editor         2
//             - Toolbar        4
//         - SlateJS editor
//             - Toolbar        5
//             - Content        4
//             - Layer          3
//

body {
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $silver;
  font-family: 'Source Sans Pro', 'Proxima Nova', sans-serif;
  line-height: 1.5;
}

html {
  box-sizing: border-box;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: #fff;
  -webkit-text-fill-color: $black !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

input, textarea, select {
  &:focus { outline: none; }
}

::-webkit-input-placeholder { color: $lightestGray; }
:-moz-placeholder           { color: $lightestGray; }
::-moz-placeholder          { color: $lightestGray; }
:-ms-input-placeholder      { color: $lightestGray; }

textarea {
  resize: none;
}

.square, .square-float {
  width: 100%;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &.square-float:before {
    float: left;
  }
}

.float-btn-menu {
  position: absolute;
  top: 2rem;
  right: 4rem;
  text-transform: uppercase;
  margin: 0;
  font-size: 1.05rem;
  padding: .7rem 1.6rem;
  color: #fff;
  background-color: #000000;
}

// Link
a.no-underscore {
  &, &:hover { text-decoration: none; }
}

.link a {
  text-decoration: none;
  color: $lightGray;
  padding: 3px 0;
  position: relative;
  font-weight: bold;
}

.link a::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 2px;
  background: rgba(0,0,0,.1);
  content: '';
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateY(5px);
}

.link a:hover::after,
.link a:focus::after {
  opacity: 1;
  transform: translateY(0px);
}

.btn {
  &.btn-no-focus:focus {
    box-shadow: none;
    border-color: transparent;
  }
}
.btn-no-focus:focus {

}

.coverImage {
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

// Toolbar alignment
/* .alignment--left {
  .public-DraftStyleDefault-block {
    text-align: left;
  }
  img, iframe {
    float: left;
  }
}

.alignment--center {
  .public-DraftStyleDefault-block {
    text-align: center;
  }
  img, iframe {
    display: block;
    margin: 0 auto;
  }
}
.alignment--right {
  .public-DraftStyleDefault-block {
    text-align: right;
  }
  img, iframe {
    float: right;
  }
} */

// Standalone Elements
.arrow-down {
  width: 15px;
  height: 15px;
  border-bottom-width: 5px;
  border-left-width: 5px;
  border-bottom-color: rgb(173,173,173);
  border-left-color: rgb(173,173,173);
  border-bottom-style: solid;
  border-left-style: solid;
  transform: rotate(-45deg);
  display: inline-block;
}

// Media queries
.lg-show {
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
  }
}
.lg-hide {
  display: block;
  @media screen and (min-width: 1024px) {
    display: none;
  }
}

// Button
.float-button {
  position: fixed;
  top: 2rem;
  right: 4rem;
  width: inherit;
  text-transform: uppercase;
  margin: 0;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logo-icon {
    fill: #fff;
    display: inline-block;
    width: 43px;
    height: 43px;
    margin: 0 18.5px;
    background-size: cover;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0,0,0,.7);

    &.nossas {
      background-color: #fff;
      position: relative;
      background-image: url('./logo-icon-white-strokes.svg');

      &:after {
        content: '';
        fill: #fff;
        display: inline-block;
        width: 35px;
        height: 35px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -17.5px;
        margin-top: -17.5px;
      }
    }
  }

.widgets--content-plugin iframe {
  max-width: 100%;
  max-height: 100%;
}
