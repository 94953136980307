.minhacampinas-scheme .widget {
  color: #444;
}

.minhacampinas-scheme .widget .button {
  color: white;
}

.minhacampinas-scheme .bg-1 {
  background-color: #12868B;
}

.minhacampinas-scheme .bg-2 {
  background-color: #FCB814;
}

.minhacampinas-scheme .bg-3 {
  background-color: #D01F53;
}

.minhacampinas-scheme .bg-1,
.minhacampinas-scheme .bg-2,
.minhacampinas-scheme .bg-3,
.minhacampinas-scheme .bg-4,
.minhacampinas-scheme .bg-5,
.minhacampinas-scheme .bg-6,
.minhacampinas-scheme .bg-7,
.minhacampinas-scheme .bg-8 {
  .widget {
    color: white;
    h1, h2, h3, h4, h5, h6 { color: white }
  }
}
