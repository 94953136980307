@import '../../../../node_modules/basscss-sass/scss/colors';
@import '../../../styles/color-variables';

.sidenav {
  width: 80px;
  // width: 280px;
  background-color: $blacker;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 6;
  overflow: hidden;
  transition: width .3s ease;

  &:hover {
    width: 280px;

    .items .item.launch-button {
      transition: border-radius .1s ease;
      border-radius: 3px;
    }
  }

  a.has-hover:hover {
    text-decoration: underline !important;
  }

  .items {
    padding-left: 0;
    text-align: center;
    color: $white;

    .item {
      display: table;
      font-weight: 600;
      position: relative;
      text-align: left;
      width: 100%;
      box-sizing: border-box;
      padding-top: .2rem;
      padding-bottom: .2rem;

      &.launch-button {
        background-color: $pagenta;
        border-radius: 50%;
        width: inherit;
        margin: 0 1rem .75rem;
        display: block;
        transition: border-radius .5s ease;
        &:hover { background-color: $pagenta; }
        > a {
          margin-left: -16px;
          > .item-content { font-weight: bold; }
        }
      }

      &.launched-item {
        background-color: $yellowGreen;
        border-radius: 50%;
        width: 50px;
        margin: 0 1rem .75rem;
        display: block;
        cursor: default;

        &:hover { background-color: $yellowGreen; }
        > a {
          margin-left: -16px;
          > .item-content { font-weight: bold; }
        }
      }

      &:hover {
        background-color: rgba(255,255,255,.05);
      }

      .item-icon {
        display: table-cell;
        vertical-align: middle;;
        height: 100%;
        font-size: 1.3rem;
        text-align: center;
        width: 80px;
        padding-left: 1px;

        i {
          width: 80px;
        }
      }

      .item-content {
        display: table-cell;
        white-space: nowrap;
        padding: 10px 0 10px 15px;
        font-weight: normal;

        .item-content-wrapper {
          width: 200px;
          overflow: hidden;
        }

        .item-community-name {
          width: 170px;
          white-space: normal;
          font-size: 1.3rem;
        }

        .item-community-change {
          width: 170px;
          display: flex;
          justify-content: space-between;
          font-size: .75rem;
          font-weight: normal;
          margin-top: 0;

          a, button {
            background: none;
            border: none;
            cursor: pointer;
            color: rgba(255,255,255,.7);

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      a, button {
        color: $white;
        text-decoration: none;
      }
    }
  }

  .items-logo {
    background-color: rgba(255,255,255,.1);
    padding-bottom: .5rem;

    .item-content {
      padding: 0;
      padding-right: 100%;
    }
  }
}
