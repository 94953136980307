@import '../_variables';

.list {
  .item {
    .more {
      line-height: $avatarHeight;
      position: absolute;
      top: 0;
      right: 0;

      i.fa {
        font-size: 1.5rem;
      }
    }
  }
}
