@font-face {
  font-family: Proxima Nova;
  src: url('/font-sets/proxima-nova/ProximaNova-Regular.otf');
}

@font-face {
  font-family: Proxima Nova;
  src: url('/font-sets/proxima-nova/ProximaNova-RegularIt.otf');
  font-style: italic;
}

@font-face {
  font-family: Proxima Nova;
  src: url('/font-sets/proxima-nova/ProximaNova-BoldIt.otf');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: Proxima Nova;
  src: url('/font-sets/proxima-nova/ProximaNova-Bold.otf');
  font-weight: bold;
}

.proxima-nova-header {
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Proxima Nova', sans-serif;
  }
}

.proxima-nova-body {
  font-family: 'Proxima Nova', sans-serif;
}

.proxima-nova-form {
  label, input {
    font-family: 'Proxima Nova', sans-serif;
  }
}
